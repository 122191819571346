<script>
import Switches from "vue-switches";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      locations: []
    }
  },
  components: { Switches },
  mounted() {
    this.fetchLocations()
  },
  methods: {
    fetchLocations() {
      this.$axios.get(`package/${this.$route.params.id}/location`)
        .then( response => {
          this.locations = response.data.result.locations
        })
    },
    onSubmit() {
      let param = new FormData()
      this.locations.forEach( loc => {
        if(loc.isPresent === true) {
          param.append('locations', loc.locationId)
        }
      })
      this.$axios.put(`package/${this.$route.params.id}/location`, param)
        .then( () => {
          Swal.fire("Success", "Package Locations Updated Successfully", "success");
        })
    }
  }
}
</script>

<template>
  <div id="edit-location">
    <div class="table-responsive mb-0">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 5%">No</th>
            <th style="width: 85%">Name</th>
            <th style="width: 10%">Applicable</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(location, index) in locations" :key="index">
            <th scope="row">{{ index+1 }}</th>
            <td>{{ location.name }}</td>
            <td>
              <switches v-model="location.isPresent" type-bold="false" color="warning" class="ml-1 mb-0"></switches>
            </td>
          </tr>
        </tbody>
      </table>
      <button type="button" @click="onSubmit" class="btn btn-primary m-1 float-right">Submit Change</button>
    </div>
  </div>
</template>