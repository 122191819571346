<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import EditData from "./edit-data";
import EditTreatment from "./edit-treatment";
import EditLocation from "./edit-location";
/**
 * Form Validation component
 */
export default {
  page: {
    title: "Form Edit Package",
    meta: [{ label: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, EditData, EditTreatment, EditLocation },
  data() {
    return {
      title: "Form Edit Package",
      items: [
        {
          text: "Home",
          href: "/home",
        },
        {
          text: "Package",
          href: "/package",
        },
        {
          text: "Edit Package",
          active: true,
        },
      ],
      tabActive: 0,
    };
  },
  mounted() {
    switch(this.$route.hash) {
      case "#data":
        this.tabActive = 0
        break;
      case "#treatment":
        this.tabActive = 1
        break;
      case "#locations":
        this.tabActive = 2
        break;
      default:
        this.tabActive = 0
        break;
    }
  },
  methods: {
    
  },
 
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs v-model="tabActive" justified nav-class="nav-tabs-custom" content-class="p-3">
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Data Package</span>
                </template>
                <EditData></EditData>
              </b-tab> 
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Treatments</span>
                </template>
                <EditTreatment></EditTreatment>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Locations</span>
                </template>
                <EditLocation></EditLocation>
              </b-tab>
            </b-tabs>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>